<template>
  <div class="main-layout">
    <LayoutsMainHeader />
    <main class="page__main">
      <div class="page__main-container">
        <NuxtPage />
      </div>
    </main>
    <LayoutsMainFooter />
  </div>
</template>

<script setup lang="ts">
const authStore = useAuthStore();
</script>

<style lang="scss" scoped>
@import '@/assets/css/layouts/main.scss';
</style>