<template>
  <header class="main-header">
    <div class="main-header__inner">
      <div class="main-header__logo">
        <img src="@/assets/img/logo.svg" @click="$router.push({ name: 'index' })">
      </div>
      <ul class="main-header__tool">
        <li v-if="authStore.isLogin">
          <NuxtLink :to="{ name: 'room' }">시작하기</NuxtLink>
        </li>
        <li>
          <NuxtLink :to="{ name: 'contact' }">가격안내</NuxtLink>
        </li>
        <li>
          <a href="https://teampl100.notion.site/ae318e93c65a48fda2055d202b19ab8f" target="_blank">
            제품안내
            <ClientOnly>
              <font-awesome-icon :icon="faArrowUpRightFromSquare" style="font-size: 0.9em; margin-left: 5px" />
            </ClientOnly>
          </a>
        </li>
        <li v-if="authStore.isLogin">
          <a href="javascript:void(0)" @click="handleLogout()">로그아웃</a>
        </li>
        <slot v-else>
          <li>
            <NuxtLink :to="{ name: 'join' }">회원가입</NuxtLink>
          </li>
          <li>
            <NuxtLink :to="{ name: 'login' }">로그인</NuxtLink>
          </li>
        </slot>
      </ul>
    </div>
  </header>
</template>

<script setup lang="ts">
import { useNewSiteToken } from '~/composables/token';
import { userLogout } from '@/composables/php/auth';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';

const authStore = useAuthStore();

const newSiteCookie = useNewSiteToken();

async function handleLogout() {
  await userLogout().then(() => {
    authStore.resetUser();
    useRouter().push({ name : 'index' });
  }).catch((e) => {
    const message = e.response && e.response.data && e.response.data.message || e.message;
      ElMessage.error(message || '알 수 없는 오류가 발생하였습니다.')
  });
}

const handlePrevSite = () => {
  newSiteCookie.value = '0';
  location.reload();
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/layouts/main/header.scss';
</style>
